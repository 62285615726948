<template>
  <div class="episode-cameras">{{ $tf('camera') }}:&nbsp;{{ cameraNames }}</div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    cameras: { type: Array, required: true }
  }
})
export default class EpisodeCameras extends Vue {
  get cameraNames() {
    return this.cameras.map(this.$store.getters.getCameraNameById).join(', ');
  }
}
</script>

<style lang="stylus">
.episode-cameras {
  font-size: 0.8125rem;
}
</style>
