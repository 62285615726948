<template>
  <div class="episode-camera-groups">{{ $tf('camera_group') }}:&nbsp;{{ cameraGroupNames }}</div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    cameraGroups: { type: Array, required: true }
  }
})
export default class EpisodeCameraGroups extends Vue {
  get cameraGroupNames() {
    return this.cameraGroups.map(this.$store.getters.getCameraGroupNameById).join(', ');
  }
}
</script>

<style lang="stylus">
.episode-camera-groups {
  font-size: 0.8125rem;
}
</style>
